
* {
  font-family: 'Work Sans', sans-serif;;
}
a {
  text-decoration: none;
  color: initial;
}
ul {
  padding-left: 0;
}
li {
  list-style: none;
}


/* Header */
/* .header {
  position: fixed;
  top: 0;
} */
.nav{

  gap:10px
}
.header,
.main {
  margin: 0 auto;
  max-width: 1140px;
}

.nav,
.navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

}

.nav li a.nav-item_logo {
  display: flex;
  flex-direction: column;
}
.nav li a .nav-item_img--logo  {
  width: 180px;
  height: 45px;
}

.nav-item_btn{
  padding: 0.5rem 1rem;
  background: rgb(67, 160, 71);
  color: white;
  border-radius: 5px;
  font-size: 22px;
  font-weight: bold;
  margin-left: 13px;

}
.nav-item_btn:hover{
  background: rgb(76, 180, 81);
}
.nav-item_pplink{
  /*color:*/
}

.link-container{
  display: none;
}

.qr-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.qr{
  height: 100px;
  margin: 20px;
}
.header-page {
  margin: 40px auto;  
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.header-page_video {
  margin: 0 auto;
}

/* Section */
.section {
  padding: 5vh 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.section_header {
  font-size: 2.5rem;
}
.section .box {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.section .box li {
  width: 33%;
  text-align: center;
}

/* How it works */
.section.how-it-works .box li .box-item_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  min-height: 500px;
}
.section .box li.box-item h3 {
  font-size: 1.75rem;
}
.section .box li.box-item h3::first-letter {
  font-size: 2rem;
  color: #A0A09F;
}
.section .box li.box-item h4 {
  font-size: 1.25rem;
}

.section.our-team .box li.box-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 3vh 0;
  width: 49%;
  min-height: 330px;
}
.section.our-team li.box-item img {
  width: 216px;
  height: 217px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: grayscale(1);
}
.section.our-team .box img.box-item__img--soc {
  width: 42px;
  height: 42px;
}


.section.feedback .box li.box-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  /* width: 33%; */
  min-height: 500px;
  background-size: cover;
  color: #ffffff;
  text-align: center;

  /* mix-blend-mode: multiply; */
  /* mix-blend-mode: exclusion; */
}

.section.feedback .box li.box-item::before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0 65 19 / 76%);
  /* mix-blend-mode: multiply; */
}

.section.feedback .box li.box-item .container {
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section.feedback .box li.box-item h4 {
  text-align: center;
  justify-self: start;
  color: rgb(255, 68, 5);
  font-size: 1.5rem;
}
.section.feedback .box li.box-item span {
  text-align: center;
  font-size: 1.3rem;
  padding: 15px;
  font-style: italic;
  /*text-align: justify;*/
}


.section.in-media .box {
  justify-content: space-evenly;
}
.section.in-media .box li {
  width: initial;
}

/*  */
.section.our-key-partners .our-key-partners-mob {
  display: none;
}

/*  */
.section.contact-us .contact-us_header {
  color: #50504f;
  font-size: 1.5rem;
}
.section.contact-us a {
  font-size: 1.3rem;
}


@media screen and (max-width: 1400px) {
  .qr-container{
    top: 60px;
  }  
}


@media screen and (max-width: 1280px) {
  .header,
  .main {
    margin: 0 auto;
    max-width: 95%;
  }
  .section.our-key-partners picture,
  .section.our-key-partners img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    padding: 1vh;
  }
  .link-container{
    display: block;
  }
  .qr-container{
    display: none;
  }
  
}

@media screen and (max-width: 748px) {
  .navigation {
    width: 100%;
  }
  /* .header-page {
    max-width: 320px;
  } */
  .section.how-it-works .box li .box-item_header {
    min-height: 500px;
  }
  .section .box li {
    margin: 3vh 0;
    width: 100%;
  }
  .feedback {
    display: none;
  }
  .section.our-key-partners .our-key-partners-desktop {
    display: none;
  }
  .section.our-key-partners .our-key-partners-mob {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .header-page_video {
    max-width: 100%;
  }
  .section.our-team .box li.box-item {
    width: 100%;
  }
  .section.our-team .box li.box-item h4 {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .nav-item_logo {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .header-page {
    width: 100%;
  }
  .header-page_video {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .navigation li {
    width: 33%;
  }
}